<template>
    <canvas ref="myChart"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        labels: {
            type: Object,
            required: true,
        },
        stats: {
            type: Object,
            required: true,
        },
    },

    mounted() {
        const ctx = this.$refs.myChart;

        const myChart = new Chart(ctx, {
            type: this.type,
            data: {
                labels: this.labels,
                datasets: [{
                    data: this.stats,
                    backgroundColor: [
                        '#F58220',
                        '#ee4630',
                        '#A5ADB6',
                        '#E26A03',
                        '#64748B',
                        '#d9d9d9',
                        '#83C0BF',
                    ],
                    hoverOffset: 4,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });
    },
};
</script>
